import * as React from 'react';
import '../styles/grid.css';
import '../styles/styles.css';
import Layout from '../components/Layout';

export default function TermsAndConditions (): React.ReactElement {

  return (
    <Layout>
      <div className="container wrapperContainer">
        <div>
          <div className="col-12">
            <h3>Allgemeine Geschäftsbedingungen B2B</h3>
            <h4>1. Einleitung, Geltung:</h4>
            <p>1.1. Die vorliegenden Allgemeinen Geschäftsbedingungen (<b>„AGB-B2B“</b>) der Triceonic GmbH, FN 563220s,
              c/o startup300 AG, Peter-Behrens-Platz 10, 4020 Linz, office(at)triceonic.com (nachfolgend kurz <b>„TRICEONIC“</b>)
              gelten für alle Rechtsgeschäfte mit und Leistungen an Unternehmen im Sinne des Unternehmensgesetzbuches (<b>„UGB“</b>)
              in der jeweils geltenden Fassung (<b>„Kunden“</b> oder einzeln geschlechtsneutral <b>„Kunde“</b>) im Zusammenhang mit der gleichnamigen Software „Triceonic“.
            </p>

            <p>1.2. TRICEONIC ist nur bereit, zu den in den vorliegenden AGB-B2B geregelten Bedingungen mit unternehmerischen Kunden zu kontrahieren
              und diesen ihre Leistungen (einschließlich Neben- und Zusatzleistungen) anzubieten. Auftrags-, Geschäfts-, Liefer-, Vertrags-
              und Zahlungsbedingungen der Kunden sind ausgeschlossen - und zwar auch dann, wenn TRICEONIC diesen nicht widersprochen hat - und gelten nur,
              sofern und soweit deren Geltung von TRICEONIC ausdrücklich und schriftlich anerkannt wurde. Konkludente (Erfüllungs-)Handlungen oder Schweigen
              von TRICEONIC gelten nicht als Akzeptierung kundenseitiger Bedingungen.</p>

            <p>1.3. Die vorliegenden AGB-B2B können unter https://www.triceonic.com (<b>„Website“</b>) elektronisch abgerufen, ausgedruckt,
              downgeloaded und auf einem Speichermedium gespeichert werden. </p>

            <h4>2. Leistungen von TRICEONIC, Software</h4>
            <p>2.1. TRICEONIC vertreibt bzw räumt Dritten Nutzungsrechte an einer von ihr selbst entwickelten Software mit der Bezeichnung „Triceonic“
              ein, welche samt den dazugehörigen Komponenten und der Benutzeroberfläche laufend bearbeitet, weiterentwickelt und adaptiert wird
              (einschließlich dieser Bearbeitungen, Weiterentwicklungen und Adaptionen nachfolgend <b>„Software“</b>).</p>

            <p>2.2. Die Software ist eine „Software-as-a-Service“ Anwendung für die (digitale) Strategieumsetzung. Sie integriert sich in die
              bestehende Systemlandschaft über vorhandene Schnittstellen. Die Software verbindet operative Aufgaben mit strategischen Zielen
              und stellt so sicher, dass jede Aktivität auf die anvisierten Ziele ausgerichtet ist. Backlogs aus Task Management Systemen wie zB: Azure DevOps,
              Jira Cloud (ab Juni 2023) oder MS365 Apps (ToDo) werden durch die Software priorisiert und auf Ziele ausgerichtet. Ein intelligenter Wiedervorlagealgorithmus
              zeigt in einer mobilen App iterativ Aufgaben aus dem Task-Management und stellt Fragen zur Priorität und Zugehörigkeit zu Zielen. Mit diesen belastbaren
              Ergebnissen baut die Software ein digitales Modell der Strategieumsetzung auf und liefert automatisiert einen priorisierten und auf Ziele ausgerichteten
              Backlog, den Arbeitsplan, wieder zurück bis in das Ursprungssystem. Die iterative Arbeitsweise hält die Daten dabei immer aktuell. Das digitale Modell zeigt,
              wie sich die Aufgaben auf die Ziele verteilen und ob die aktuelle Umsetzung nach Plan verläuft. </p>

            <p>2.3. Die genauen Leistungen (wie zB Installation/Implementierung/Integration in die Systeme des Kunden/Inbetriebnahme der Software, Weiterentwicklungen,
              Einschulungen, Support, etc) werden mit dem Kunden im Lizenzvertrag individuell vereinbart. Darüber hinaus bietet TRICEONIC auch gesondert diverse
              Beratungsleistungen im Zusammenhang mit der Software an.</p>

            <h4>3. Vertragsabschluss, Voraussetzungen</h4>

            <u>3.1. Vertragsabschluss</u>

            <p>3.1.1. Eine Leistungserbringung durch TRICEONIC setzt den rechtswirksamen Abschluss eines Software-Lizenzertrages mit TRICEONIC voraus, welcher durch die rechtsverbindliche
              Annahme eines rechtsverbindlichen Angebotes des Kunden durch TRICEONIC zustande kommt.</p>

            <p>3.1.2. Angebote von TRICEONIC sind unverbindlich und stellen nur eine Aufforderung an Kunden dar, ein Angebot an TRICEONIC zu legen. Erst das Angebot (die Bestellung)
              des Kunden stellt ein rechtverbindliches Angebot an TRICEONIC zum Abschluss eines Vertrages unter Einbeziehung der gegenständlichen AGB-B2B dar.</p>

            <p>3.1.3. Im Falle einer elektronischen Bestellung schließt der Kunde sein Angebot durch aktives Anklicken des Buttons „Zahlungspflichtig bestellen“ am Ende
              des Bestellvorganges ab, womit das Angebot an TRICEONIC gesendet wird. Die automatische Empfangsbestätigung per E-Mail, mit welcher dem Kunden der
              Eingang des Angebotes / der Bestellung bei TRICEONIC bestätigt wird, dokumentiert lediglich den technischen Eingang des Angebotes des Kunden bei
              TRICEONIC und stellt noch keine Vertragsannahme von TRICEONIC dar.</p>

            <u>3.2. Technische Voraussetzungen zum Betrieb der Software</u>

            <p>3.2.1. Um die Software nutzen zu können, sind kundenseitig die dafür erforderlichen und von TRICEONIC bekannt gegebenen technischen Voraussetzungen auf
              eigene Kosten und auf eigene Verantwortung zu erfüllen. Kunden haben insbesondere auf eigene Kosten für einen aufrechten Zugang zum Internet zu sorgen,
              wobei als Browser Google Chrome empfohlen wird. Kunden wird, nicht zuletzt zur Vermeidung von Sicherheitsrisiken, empfohlen, ihre Internetbrowser stets
              auf dem aktuellen Stand zu halten. Sollten sich die allgemeinen technischen Standards im Internet und damit auch diejenigen der Software in Zukunft
              verändern, so haben sich Kunden diesen Veränderungen auf eigene Kosten anzupassen. Das Risiko, dass Leistungen mangels technischer Voraussetzungen
              nicht ausgeführt werden können, liegt beim Kunden.</p>

            <p>3.2.2. TRICEONIC ist bemüht, eine möglichst hohe und sichere Verfügbarkeit und Funktionalität der Software sowie der Datenverarbeitung, -übertragung und
              -sicherheit zu gewährleisten und Störungen und Fehler so gering wie möglich zu halten. TRICEONIC schuldet aber keine ständige, uneingeschränkte,
              störungs- und fehlerfreie Verfügbarkeit und Funktionsfähigkeit der Software und diesbezüglich keinen Erfolg. Für etwaige Verluste, Beschädigungen
              und Sicherheitsbeeinträchtigungen von Daten, die außerhalb der Sphäre von TRICEONIC (zB während der Übertragung über das Internet oder in der Sphäre
              des Kunden) eintreten, ist TRICEONIC nicht verantwortlich.</p>

            <u>3.3. Datenschutzrechtliche Voraussetzungen beim Kunden </u>

            <p> 3.3.1. Bei der Nutzung der Software werden auch personenbezogene Daten im Sinne des Art 4 Verordnung (EU) 2016/679 (Datenschutz-Grundverordnung - <b>„DSGVO“</b>) oder Daten
              technischer Natur verarbeitet, die als personenbezogen gelten könnten oder die zur Ermittlung betroffenen Personen oder personenbezogener Daten verwendet werden könnten,
              und zwar von Personen oder Personengruppen des Kunden, die mit TRICEONIC in keinem direkten Vertragsverhältnis stehen. Daher hat der Kunde vor der Nutzung der Software
              auf eigene Kosten und Verantwortung die Voraussetzungen für eine datenschutzkonforme Nutzung der Software sowie für eine damit verbundene Verarbeitung personenbezogener
              Daten im Betrieb des Kunden zu schaffen.</p>

            <p>3.3.2. Der Kunde entscheidet allein über die Zwecke und den Einsatz der Software als Mittel der Verarbeitung personenbezogener Daten und ist daher gegenüber
              jenen Personen, welche die Software im Unternehmen des Kunden nutzen, Verantwortlicher im Sinne des Art 4 Z 7 DSGVO.</p>

            <p>3.3.3. Der Kunde ist folglich auch alleine verantwortlich für:</p>

            <p>i. die Einhaltung der ihm als Verantwortlichen der Datenverarbeitung obliegenden datenschutzrechtlichen Verpflichtungen gemäß der DSGVO,
              den jeweils einschlägigen nationalen Datenschutzgesetzen (in Österreich: Datenschutzgesetz - <b>„DSG“</b>) sowie allfälliger weiterer gegenwärtiger
              und künftiger Datenschutzvorschriften, insbesondere für die Rechtmäßigkeit der Datenverarbeitung und der Datenweitergabe bzw -offenlegung an
              TRICEONIC, für die Wahrnehmung der Rechte der betroffenen Personen und für die Informationspflichten gemäß Art 12 ff DSGVO,</p>

            <p>ii. die Einhaltung der jeweils einschlägigen nationalen Vorschriften im Beschäftigungskontext (siehe Art 88 DSGVO), denen der Kunde als Arbeitgeber
              (Dienstgeber) unterworfen ist (in Österreich insbesondere des DSG, des Arbeitsverfassungsgesetzes (<b>„ArbVG“</b>) und der jeweils einschlägigen Kollektivverträge
              und Betriebsvereinbarungen); so hat der Kunde als Arbeitgeber (Dienstgeber) in Österreich insbesondere für die Erteilung entsprechender Anordnungen gemäß
              § 6 Abs 2 DSG sowie für eine allenfalls erforderliche Einbindung bzw Mitwirkung des Betriebsrates gemäß §§ 96 ff ArbVG zu sorgen; und</p>

            <p>iii. die Einholung und die Aufrechterhaltung der für die Nutzung der Software allenfalls erforderlichen Einwilligungen gemäß Art 6 Abs 1 und Art 7 f
              sowie - erforderlichenfalls - Art 9 Abs 2 lit a) DSGVO in Verbindung mit den jeweils einschlägigen nationalen Datenschutzgesetzen (in Österreich
              insbesondere § 4 Abs 4 DSG).
            </p>
            <p>3.3.4. Informationen über die Verarbeitung personenbezogener Daten im Unternehmen von TRICEONIC enthalten die auf ihrer Website abrufbaren Datenschutzinformationen.</p>

            <h4>4. Vertragslaufzeit</h4>
            <p>4.1. Die Bereitstellung der Software ist ein Dauerschuldverhältnis. Auch Beratungsleistungen im Zusammenhang mit der Software werden von TRICEONIC in der Regel nicht als Zielschuldverhältnis, sondern nur als Dauerschuldverhältnis auf bestimmte oder unbestimmte Dauer angeboten. Die jeweilige Vertragslaufzeit wird mit dem Kunden individuell vereinbart.</p>
            <u>4.2. Vorzeitige Vertragsauflösung</u>
            <p>Alle Verträge mit TRICEONIC - ob Dauerschuldverhältnisse mit einer bestimmten oder unbestimmten Dauer oder Zielschuldverhältnisse -
              können von TRICEONIC bei Vorliegen wichtiger Gründe mit sofortiger Wirkung mittels nachweislicher Erklärung in Textform (zB eingeschriebener
              Brief oder E-Mail mit Empfangs- bzw Lesebestätigung) aufgelöst werden (<b>„Auflösung aus wichtigem Grund“</b>).</p>
            <p>Wichtige Gründe, welche TRICEONIC zur sofortigen Vertragsauflösung berechtigen, sind insbesondere:</p>
            <p>- Zahlungsverzug des Kunden trotz vorheriger Setzung oder Gewährung einer Nachfrist,<br />
              - Vorliegen der Voraussetzungen für die Eröffnung eines Insolvenzverfahrens oder die Abweisung eines solchen Antrags bei einer Vertragspartei, was insbesondere bei einem Ansuchen um Stundung oder um außergerichtlichen Ausgleich anzunehmen ist<br />
              - wesentliche Verstöße des Kunden gegen einen mit TRICEONIC abgeschlossenen Vertrag oder gegen gesetzliche Bestimmungen, die das Vertrauen in den Kunden erschüttern, auch wenn noch keine Unzumutbarkeit des weiteren Festhaltens am Vertrag vorliegt,<br />
              - nicht gestattete Weitergabe oder Gestattung der Nutzung der Software oder von Teilen davon an bzw durch Dritte und / oder deren Autorisierung, die nur vom Kunden lizenzierte Software zu verwenden,<br />
              - eine unberechtigte, missbräuchliche oder in betrügerischer Absicht vorgenommene Nutzung der Software sowie der Verdacht hierauf,<br />
              - nicht gestattete Verwendung, Verwertung, Veränderung, Vervielfältigung (einschließlich Erstellung von Kopien, Scans oder Screenshots) und / oder Verbreitung der Software oder von Teilen davon durch den Kunden,<br />
              - eine Umgehung sowie Versuche einer Umgehung der von TRICEONIC oder Dritten vorgesehenen technischen Maßnahmen, wie zB Kopierschutz-Einstellungen, die dazu dienen, die Software vor nicht gestatteter Verwendung, Verwertung, Veränderung, Vervielfältigung und / oder Verbreitung zu schützen.</p>
            <p>Im Falle einer Vertragsauflösung aus wichtigem Grund seitens TRICEONIC steht dieser - vorbehaltlich weiterer gesetzlicher Ansprüche - das volle Entgelt für die von ihr erbrachten Leistungen bis zum Zeitpunkt der Übermittlung der Auflösungserklärung zu.</p>

            <h4>5. Verpflichtungen des Kunden </h4>
            <p>5.1. Nachdem TRICEONIC für den Kunden eine Kundeninstanz eröffnet hat, in der die Software für den Kunden betrieben wird, kann von TRICEONIC für bestimmte Personen des Kunden ein Benutzerkonto eröffnet werden, wodurch sich diese Personen durch Eingabe ihrer Anmeldedaten (Username, E-Mail-Adresse und das von ihnen gewählte Passwort) jederzeit Zugang zur Software verschaffen können. Jede registrierte Person des Kunden darf, sofern nicht anders mit TRICEONIC vereinbart, nur ein Benutzerkonto haben. Das Benutzerkonto ist nicht übertragbar. Der Kunde ist verpflichtet, dafür zu sorgen, dass seine registrierten Personen ihre persönlichen Anmeldedaten, mit denen sie registriert sind, jeweils aktuell und geheim halten sowie ihr Passwort vertraulich behandeln und mit der nötigen Sorgfalt sicherstellen, dass Dritte hierauf keinen Zugriff erlangen. Eine Weitergabe oder Offenlegung der Anmeldedaten an Dritte und / oder deren Autorisierung, das Benutzerkonto zu verwenden, ist ausdrücklich untersagt. Kunden und deren Personen haben unverzüglich jede unberechtigte, missbräuchliche oder in betrügerischer Absicht vorgenommene Nutzung ihres Benutzerkontos sowie den Verdacht, dass ihr Benutzerkonto einer solchen Gefahr ausgesetzt sein könnte, an TRICEONIC zu melden und ihr Passwort zu ändern. Gleiches gilt auch bei der Nutzung von Authentifizierungssystemen Dritter (zB über AWS Secrets Manager und OAuth).</p>
            <p>5.2. Während der Nutzung der Software (gegebenenfalls auch über Apps) sind die angemeldeten Personen des Kunden grundsätzlich permanent eingeloggt („soft login“), bis sie sich aktiv mittels Logout abmelden. Auf die Gefahr, dass Dritte auf Benutzerkonten und alle hierin gespeicherten persönlichen Daten zugreifen können, wenn sie über angemeldete Kunden-Endgeräte verfügen, und dass ein angemeldetes Endgerät auch ohne dessen aktive Nutzung möglicherweise im Hintergrund Daten austauscht, wodurch das den Kunden von ihren Internetanbieter zur Verfügung stehende Datenvolumen genutzt und beeinträchtigt werden kann, wird ausdrücklich hingewiesen. Kunden bzw ihren Personen wird daher empfohlen, sich nach dem Ende einer Nutzung durch aktives Anklicken des Logout-Buttons aktiv abzumelden. Gleiches gilt auch bei der Nutzung von Authentifizierungssystemen Dritter (zB über AWS Secrets Manager und OAuth).</p>
            <p>5.3. Benutzerkonten können über Ersuchen des Kunden von TRICEONIC jederzeit endgültig gelöscht oder vorübergehend stillgelegt werden. Bei Vorliegen berechtigter Gründe zu glauben, dass ein Benutzerkonto unberechtigt, missbräuchlich oder in betrügerischer Absicht genutzt wird, ist auch TRICEONIC von sich aus berechtigt, das Benutzerkonto der betreffenden Person (bei Gefahr in Verzug mit sofortiger Wirkung und ohne Vorankündigung) temporär zu sperren oder endgültig zu löschen und ihre Leistungen zu verweigern.</p>

            <h4>6. Gewährleistung, Schadenersatz </h4>
            <p>6.1. TRICEONIC schuldet keinen wie immer gearteten Erfolg und übernimmt keinerlei Gewährleistung, Haftung und Verantwortung für eine bestimmte Verwertbarkeit bzw einen bestimmten Verwertungserfolg der Ergebnisse der Software sowie dafür, wie der Kunde die Ergebnisse nutzt und mit den Ergebnissen umgeht. </p>
            <p>6.2. Schadenersatzansprüche gegen TRICEONIC sind - mit Ausnahme von Personenschäden - in Fällen leichter und grober Fahrlässigkeit ausgeschlossen. TRICEONIC haftet daher - ausgenommen für Personenschäden - nur bei Vorsatz oder krass grober Fahrlässigkeit. </p>
            <p>6.3. Die Haftung von TRICEONIC für bloße (reine) Vermögensschäden, immaterielle Schäden, Folgeschäden, Schäden aus Ansprüchen Dritter gegen den Kunden, entgangenen Gewinn sowie bei höherer Gewalt (wie insbesondere bei Naturereignissen, Epidemien / Pandemien, kriegerischen Auseinandersetzungen, Terroranschlägen, Streiks, hoheitlichen Eingriffen, Störungen der Energiezufuhr oder der Kommunikation) ist ausgeschlossen.</p>
            <p>6.4. Soweit die Haftung von TRICEONIC ausgeschlossen ist, gilt dies auch für ihre Eigentümer, Organe und deren Mitglieder, Mitarbeiter, Vertreter und sonstigen Erfüllungsgehilfen. </p>
            <p>6.5. Sofern eine Haftung nicht zulässigerweise ausgeschlossen ist, ist die Haftung von TRICEONIC - vorbehaltlich weitergehender Haftungsbegrenzungen in anderen Vorschriften - auf die für den konkreten Schadensfall zur Verfügung stehende Haftpflichtversicherungssumme beschränkt. TRICEONIC haftet auch nur für Schädigungen durch Personen, für welche sie gemäß zwingenden Rechtsvorschriften einzustehen hat. Der Kunde trägt die Beweislast für das Vorliegen eines Verschuldens von TRICEONIC oder ihrer zwingend zurechenbarer Erfüllungsgehilfen.</p>

            <h4>7. Fremdinhalte</h4>
            <p>TRICEONIC leistet nur Gewähr und haftet nur für die Richtigkeit und Vollständigkeit eigener Inhalte (einschließlich derer ihrer zwingend zurechenbarer Erfüllungsgehilfen), nicht jedoch für Inhalte welcher Art auch immer, insbesondere von Texten, Informationen, Fotos, Videos, Designs, Logos, etc, von anderen Personen (einschließlich von Inhalten des Kunden selbst) (<b>„Fremdinhalte“</b>), welche in die Gestaltung der Software eingebunden oder über diese übermittelt werden. Solche Fremdinhalte werden von TRICEONIC nicht auf Richtigkeit und Vollständigkeit überprüft. TRICEONIC übernimmt daher keinerlei Gewährleistung, Haftung oder Verantwortung für die Vollständigkeit und Richtigkeit von Fremdinhalten sowie für die Auswahl der für die Fremdinhalte verantwortlichen Personen. </p>

            <h4>8. Immaterialgüter- und Urheberrechte </h4>
            <p>8.1. Sofern mit dem Kunden nicht ausdrücklich schriftlich etwas anderes vereinbart wurde - etwa durch gesonderte Lizenzverträge Nutzungsrechte an der Software eingeräumt werden -, verbleiben sämtliche Immaterial- und sonstigen gewerblichen Schutzrechte hinsichtlich der Software (einschließlich allfälliger Weiterentwicklungen) im geistigen Eigentum bzw im Urheberrecht von TRICEONIC. Kunden erhalten bzw erwerben von TRICEONIC ohne vorherige ausdrückliche schriftliche Vereinbarung keine wie immer gearteten Eigentums-, Urheber-, Werknutzungs-, Immaterial- und sonstigen gewerblichen Schutzrechte. </p>
            <p>8.2. Dem Kunden ist nur die vereinbarungs- und bestimmungsgemäße, betriebliche, nicht ausschließliche, nicht übertragbare, nicht lizenzierbare, unveränderliche, räumlich und sachlich sowie auf die Vertragsdauer beschränkte Nutzung der Software und allfälliger Softwarekomponenten nach Maßgabe und unter Einhaltung der abgeschlossenen Lizenzverträge, dieser AGB-B2B und der jeweils geltenden Rechtsvorschriften gestattet. Jede darüberhinausgehende Verwendung (insbesondere für andere als eigene betriebliche Zwecke), Verwertung, Veränderung, Dekompilierung, Vervielfältigung (Ausnahme: Back-Ups), Lizenzierung, Verbreitung (insbesondere die Weitergabe an Dritte), Weiterentwicklung oder Weitergabe (wenn auch nur unentgeltlich) ist unzulässig und bedarf der vorherigen ausdrücklichen und schriftlichen Zustimmung von TRICEONIC. Dies gilt nicht für Änderungen, die für die Beseitigung von Fehlern notwendig sind, sofern TRICEONIC die Fehlerbeseitigung ablehnt oder zur Fehlerbeseitigung aus welchen Gründen auch immer nicht in der Lage ist. Der Kunde hat keinen Anspruch auf Herausgabe und / oder Übertragung des Maschinen- und Quellcode („Source-Code“) der Software. TRICEONIC hat ist auch nicht verpflichtet, dem Kunden weitere Rechte einzuräumen. </p>

            <h4>9. Vertraulichkeit </h4>
            <p>9.1. Sofern mit dem Kunden nicht ausdrücklich schriftlich etwas anderes vereinbart wurde, ist der Kunde verpflichtet, über den Inhalt dieses Vertrages und über alle betriebs-, geschäfts- und vertragsrelevanten Umstände (insbesondere über alle Betriebs- und Geschäftsgeheimnisse von TRICEONIC, auch wenn diese nicht explizit als solche gekennzeichnet sind, wie insbesondere über vertragliche Konditionen, sowie über sämtliche im Zuge des Vertragsverhältnisses stattgefundenen Vorgänge und Gespräche aller Art), die dem Kunden im Rahmen der Vertragsbeziehung offen gelegt oder bekannt geworden sind und künftig während der Vertragslaufzeit offen gelegt oder bekannt werden, während der gesamten Vertragslaufzeit und über das Ende der Vertragslaufzeit hinaus strengstes Stillschweigen gegenüber jedermann zu bewahren und geheim zu halten. Betriebs-, geschäfts- und vertragsrelevante Daten und Informationen dürfen weder unmittelbar noch mittelbar für andere Zwecke als für die Erfüllung dieses Vertrages verwertet werden. Der Kunde ist verpflichtet, die Verpflichtung zur Geheimhaltung vollumfänglich auf alle Personen (insbesondere Mitarbeiter, Gesellschaftsorgane, Gesellschafter, Erfüllungsgehilfen, Subunternehmer und Vertragspartner), welche Zugang zu oder Zugriff auf betriebs-, geschäfts- und vertragsrelevanten Umstände haben, zu überbinden.</p>
            <p>9.2. Diese Verpflichtung gilt nicht bei: <br />
              i. schriftlicher Einwilligung von TRICEONIC vor der Offenlegung,<br />
              ii. einer Verpflichtung zur Offenlegung von Rechts wegen, insbesondere aufgrund der Gesetze oder gerichtlicher oder behördlicher Anordnung,<br />
              iii. der Offenlegung an Personen, die zur Verschwiegenheit verpflichtet sind, und<br />
              iv. der Notwendigkeit zur Durchsetzung von Ansprüchen aus oder im Zusammenhang mit einem Vertragsvertragsverhältnis zwischen TRICEONIC und dem Kunden.</p>
            <p>9.3. Die Vertraulichkeitsverpflichtung gemäß diesem Punkt besteht auf unbegrenzte Dauer und bleibt auch nach Beendigung dieses Vertrages (aus welchem Grunde auch immer) vollinhaltlich aufrecht bestehen.</p>

            <h4>10. Sonstige Bestimmungen</h4>
            <p><u>10.1. Abschließende Regelung</u><br />
              Sofern zwischen TRICEONIC und dem Kunden nicht ausdrücklich schriftlich etwas anderes vereinbart wurde, werden im Falle des Abschlusses eines diesen AGB-B2B unterliegenden Vertrages die Rechtsbeziehungen der Vertragsparteien abschließend geregelt. </p>
            <p><u>10.2. Anfechtungsverzicht</u><br />
              Sofern zwischen TRICEONIC und dem Kunden nicht ausdrücklich schriftlich etwas anderes vereinbart wurde, verzichtet der Kunde - soweit nach zwingendem Recht zulässig - darauf, einen diesen AGB-B2B unterliegenden Vertrag zwecks Anpassung oder Aufhebung (jedenfalls aber wegen Irrtums, Fehlens oder Wegfalls der Geschäftsgrundlage und wegen laesio enormis) anzufechten oder geltend zu machen, er sei nicht gültig zustande gekommen oder nichtig, und anerkennt, dass Leistung und Gegenleistung in einem ortsüblichen und angemessenen Verhältnis stehen und er auch bei Vorliegen des Tatbestandes des § 934 ABGB den Vertrag mit gleichem Inhalt abgeschlossen hätte.</p>

            <p><u>10.3. Anwendbares Recht, Gerichtsstand</u><br />
              10.3.1. Sofern zwischen TRICEONIC und dem Kunden nicht ausdrücklich schriftlich etwas anderes vereinbart wurde, unterliegen sämtliche vertragliche und außervertragliche Schuldverhältnisse zwischen TRICEONIC und dem Kunden - einschließlich der Frage des gültigen Zustandekommens, der Einbeziehung und Geltung der vorliegenden AGB-B2B und der Vor- und Nachwirkungen - ausschließlich österreichischem materiellem Recht unter Ausschluss der Kollisionsnormen des Internationalen Privatrechts und des UN-Kaufrechtsabkommens (CISG).</p>
            <p>10.3.2. Sofern zwischen TRICEONIC und dem Kunden nicht ausdrücklich schriftlich etwas anderes vereinbart wurde, so gilt der Gerichtsstand des jeweils sachlich und örtlich für TRICEONIC zuständigen Gerichtes als vereinbart. TRICEONIC ist jedoch berechtigt, Ansprüche nach ihrer freien Wahl auch an allen in Betracht kommenden Gerichtsständen geltend zu machen. Streitigkeiten welcher Art auch immer sowie die Geltendmachung von Rechten durch TRICEONIC entbinden den Kunden nicht von der Verpflichtung zur (weiteren) Erbringung der von ihm geschuldeten Leistungen. </p>

            <u>10.4. Aufrechnungs- und Zurückbehaltungsverbot</u><br />
            <p>Sofern zwischen TRICEONIC und dem Kunden nicht ausdrücklich schriftlich etwas anderes vereinbart wurde, sind dem Kunden aufgrund dispositiver gesetzlicher Vorschriften oder Normen zustehende Aufrechnungs- und Zurückbehaltungsrechte (insbesondere gemäß §§ 471, 1052 ABGB und §§ 369 f UGB idgF) ausgeschlossen und ist eine Aufrechnung gegen die Ansprüche von TRICEONIC mit Gegenforderungen welcher Art auch immer ausgeschlossen. </p>

            <u>10.5. Erfüllungsort</u><br />
            <p>Sofern zwischen TRICEONIC und dem Kunden nicht ausdrücklich schriftlich etwas anderes vereinbart wurde, ist Erfüllungsort für Leistungen jedweder Art der Sitz von TRICEONIC</p>

            <u>10.6. Formgebote</u><br />
            <p>Sofern zwischen TRICEONIC und dem Kunden nicht ausdrücklich schriftlich etwas anderes vereinbart wurde, bestehen keine mündlichen Nebenabreden und bedürfen Änderungen und Ergänzungen eines diesen AGB-B2B unterliegenden Vertrages (einschließlich etwaiger Anlagen, Nebenabreden und Zusatzvereinbarungen sowie dieser AGB-B2B) zu ihrer Wirksamkeit der Schriftform im Sinne des § 886 ABGB. Gleiches gilt für das Abgehen von diesem Schriftformerfordernis. Sofern zwischen TRICEONIC und dem Kunden nicht ausdrücklich schriftlich etwas anderes vereinbart wurde, kann aus Handlungen oder Unterlassungen von TRICEONIC (zB Zahlungen) kein Verzicht auf Rechte und kein Anerkenntnis von Pflichten abgeleitet werden, wenn ein solcher nicht ausdrücklich schriftlich erklärt wurde.</p>
            <u>10.7. Vergünstigungen, Vorteile</u><br />
            <p>Sofern TRICEONIC Vergünstigungs- oder Vorteilsaktionen anbietet, gilt dies nur bis auf jederzeitigen und unbeschränkten Widerruf. Eine Barablöse derselben ist ausgeschlossen</p>
            <u>10.8.Mitteilungen</u><br />
            <p>Sofern zwischen TRICEONIC und dem Kunden nicht ausdrücklich schriftlich etwas anderes vereinbart wurde, erfolgen Mitteilungen und Erklärungen zwischen den Vertragsparteien – soweit sich aus einem diesen AGB-B2B unterliegenden Vertrag oder aus zwingenden gesetzlichen Bestimmungen nichts anderes ergibt – schriftlich oder per E-Mail gegen Empfangs- bzw Lesebestätigung an die im Vertrag angeführte oder eine davon abweichende, nachweislich bekanntgegebene (E-Mail-)Adresse oder persönlich gegen Empfangsbestätigung. Mitteilungen und Erklärungen gelten als rechtzeitig, wenn sie am letzten Tag der Frist abgeschickt wurden.</p>

            <u>10.9. Personenmehrheit</u><br />
            <p>10.9.1. Für den Fall, dass Leistungen von TRICEONIC aus einem Vertrag mit (nur) einem Kunden (zB eine Konzernholding) auch gegenüber anderen Personen (zB einzelnen oder allen Konzerngesellschaften) erbracht werden sollen, bleibt alleine der vertragsschließende Kunde Vertragspartner von TRICEONIC und damit auch alleine verantwortlich für die Einhaltung der ihm gemäß dem diesen AGB-B2B unterliegenden Vertrag sowie den geltenden Rechtsvorschriften auferlegten Pflichten. Der Vertrag ist in diesem Fall als unechter Vertrag zugunsten dieser anderen Personen zu qualifizieren. </p>
            <p>10.9.2. Dagegen sind mehrere Personen auf Seiten des Kunden jeweils für die Einhaltung der ihnen nach dem diesen AGB-B2B unterliegenden Vertrag sowie den geltenden Rechtsvorschriften auferlegten Pflichten verantwortlich und haften TRICEONIC gegenüber solidarisch für die Erfüllung und Einhaltung der ihnen obliegenden Leistungen, Pflichten und Schäden. </p>

            <u>10.10. Rechtsnachfolge, Übertragung</u><br />
            <p>Sofern zwischen TRICEONIC und dem Kunden nicht ausdrücklich schriftlich etwas anderes vereinbart wurde, ist der Kunde verpflichtet, einen diesen AGB-B2B unterliegenden Vertrag sowie die darin enthaltenen Rechte und Pflichten vollinhaltlich und uneingeschränkt auf allfällige Einzel- oder Gesamtrechtsnachfolger zu überbinden und diese wiederum zur Überbindung zu verpflichten. Die Übertragung eines diesen AGB-B2B unterliegenden Vertrages (dh der gesamten Vertragsposition) sowie einzelner darin geregelter Rechte und Pflichten durch den Kunden im Wege der Einzel- oder Gesamtrechtsnachfolge bedarf der vorherigen schriftlichen Zustimmung von TRICEONIC. TRICEONIC ist hingegen berechtigt, den abgeschlossenen Vertrag (dh die gesamte Vertragsposition) sowie einzelne darin geregelte Rechte und Pflichten ganz oder teilweise auf einen Dritten zu übertragen. Der Kunde erteilt mit Vertragsabschluss seine Zustimmung zur Rechtsnachfolge auf Seiten der TRICEONIC.</p>
            <u>10.11. Salvatorische Klausel</u><br />
            <p>Sollte eine Bestimmung eines diesen AGB-B2B abgeschlossenen Vertrages oder dieser AGB-B2B ganz oder teilweise unwirksam oder undurchführbar sein oder werden, so wird dadurch die Wirksamkeit der übrigen Bestimmungen nicht berührt. Die Vertragsparteien verpflichten sich in diesem Fall, anstelle der nicht rechtswirksamen oder undurchführbaren Bestimmung unverzüglich eine solche schriftlich festzulegen, die im Rahmen des rechtlich Möglichen dem am nächsten kommt, was von den Vertragsparteien nach dem ursprünglichen Sinn und Zweck der unwirksamen oder undurchführbaren Bestimmung gewollt war und dem wirtschaftlichen Zweck der unwirksamen oder undurchführbaren Bestimmung möglichst nahekommt. Entsprechendes gilt im Falle von ergänzungsbedürftigen Regelungslücken.</p>
            <u>10.12. Sprachliche Abweichungen </u><br />
            <p>Sofern diese AGB-B2B ganz oder teilweise auch in anderen Sprachen bereitgestellt werden, übernimmt TRICEONIC keine Gewähr und Haftung für die (zugekaufte) Übersetzung dieser AGB-B2B in andere Sprachen. Im Falle von sprachlichen Abweichungen der in anderen Sprachen verfassten AGB-B2B, geht die deutsche Fassung anderen Sprachfassungen vor.</p>
            <u> 10.13. Unterfertigungsberechtigung </u><br />
            <p>Die kundenseitig einen diesen AGB-B2B unterliegenden Vertrag mit TRICEONIC unterfertigende(n) Person(en) garantiert(en) durch ihre Unterschrift bei sonstiger persönlicher Haftung, dass sie berechtigt ist(sind), rechtsverbindlich für den Kunden zu zeichnen und berechtigt zu sein, den Vertrag mit TRICEONIC abzuschließen. </p>
          </div>
        </div >
      </div >
    </Layout >
  );
}
